<template>
  <div>
    <!-- 添加应用列表窗口 -->
    <div class="applicationUtil_view">
      <div class="applicationUtil_category">
        <div class="applicationUtil_category_title">应用分组</div>
        <div
          :class="activeCategory==item.value?'applicationUtil_category_view_selected':'applicationUtil_category_view' "
          v-for="(item,index) in applicationCategory"
          :key="index"
          @click="switchActiveCategory(item)"
        >{{item.label}}</div>
      </div>
      <div class="applicationUtil_right">
        <el-row :gutter="20">
          <el-col
            :xl="8"
            :lg="12"
            :md="24"
            :sm="24"
            :xs="24"
            :offset="0"
            v-for="market in marketList"
            :key="market.apkId"
            style="margin-bottom:10px;cursor: pointer;"
          >
            <div v-if="market.isShowSelected" class="applicationUtil_card_notSelected">
              <div class="applicationUtil_list">
                <div>
                  <img :src="market.imgUrl" width="70" height="70" style="color:#C0C4CC" />
                </div>
                <div class="applicationUtil_list_right">
                  <div>{{market.cnName}}</div>
                  <div style="margin-top:10px">{{market.version}}</div>
                  <div
                    style="position:absolute;bottom:0;right:10px"
                    v-if="userInfo.userId==market.userId"
                  >
                    <el-button type="text" size="mini" @click.stop="openEditMyApp(market)">编辑</el-button>
                    <el-button type="text" size="mini" @click.stop="onSubmitDelMyApp(market)">删除</el-button>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="market.selected?'applicationUtil_card_selected':'applicationUtil_card'"
              @click="addApkListByMarket(market)"
              v-else
            >
              <div class="applicationUtil_list">
                <div>
                  <img :src="market.imgUrl" width="70" height="70" />
                </div>
                <div class="applicationUtil_list_right">
                  <div>{{market.cnName}}</div>
                  <div style="margin-top:10px">{{market.version}}</div>
                  <div
                    style="position:absolute;bottom:0;right:10px"
                    v-if="userInfo.userId==market.userId"
                  >
                    <el-button type="text" size="mini" @click.stop="openEditMyApp(market)">编辑</el-button>
                    <el-button type="text" size="mini" @click.stop="onSubmitDelMyApp(market)">删除</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 修改应用 -->
    <el-dialog
      :visible.sync="dialogEditMyApp"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogEditMyApp"
      append-to-body
    >
      <span slot="title">编辑应用</span>
      <div>
        <el-form
          :model="editForm"
          ref="editForm"
          label-width="80px"
          size="small"
          style="padding:20px"
          hide-required-asterisk
        >
          <el-form-item label="图标">
            <el-dropdown placement="bottom">
              <div>
                <div class="avatar-uploader-logo">
                  <el-image
                    :src="fileUrl+ editForm.imgUrl"
                    style="width: 100px; height: 100px;background:rgba(235,235,235,0.5);"
                    fit="contain"
                  ></el-image>
                </div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="dialogEditFileUpload=true"
                  style="padding:0"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                <el-dropdown-item style="padding:0">
                  <el-upload
                    style="text-align:center"
                    :action="actionUrl"
                    accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                    :headers="header"
                    :data="{type:0,category:'APP图标'}"
                    :show-file-list="false"
                    :on-success="handleImgEditSuccess"
                    :before-upload="beforeImgEditUpload"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>

          <el-form-item label="应用分类">
            <el-select v-model="editForm.apkType" placeholder="请选择" style="width:100%">
              <el-option
                v-for="item in applicationCategory"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="中文名称"
            prop="cnName"
            :rules="{ required: true, message: '请输入中文名称', trigger: 'blur' }"
          >
            <el-input v-model="editForm.cnName" placeholder="请输入中文名称"></el-input>
          </el-form-item>
          <el-form-item
            label="英文名称"
            prop="enName"
            :rules="{ required: true, message: '请输入英文名称', trigger: 'blur' }"
          >
            <el-input v-model="editForm.enName" placeholder="请输入英文名称"></el-input>
          </el-form-item>
          <el-form-item
            label="应用介绍"
            prop="apkIntroduction"
            :rules="{ required: true, message: '请输入应用介绍', trigger: 'blur' }"
          >
            <el-input
              v-model="editForm.apkIntroduction"
              type="textarea"
              :rows="6"
              placeholder="请输入应用介绍"
            ></el-input>
          </el-form-item>
          <el-form-item label="APK地址">
            <el-input v-model="editForm.apkUrl" disabled>
              <template slot="append">
                <el-upload
                  ref="apk-editUpload"
                  :action="actionUrlApk"
                  accept=".apk"
                  :on-success="handleEditSuccess"
                  :before-upload="handleEditBefore"
                  :headers="header"
                  :show-file-list="false"
                >
                  <el-button style="color:#5e72f9">上传APK</el-button>
                </el-upload>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer">
        <el-button size="small" @click="dialogEditMyApp=false">取消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditMyApp">确定</el-button>
      </span>
    </el-dialog>

    <!-- 修改窗口--添加图片 -->
    <el-dialog
      :visible.sync="dialogEditFileUpload"
      :close-on-click-modal="false"
      width="55%"
      append-to-body
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'APP图标'" v-if="dialogEditFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditImgUrl" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getApkMarketApi, delAppStoreApi, addAppStoreApi } from "../api/data";
import Aes from "../utils/aes";
import FileUpload from "./FileUpload.vue";
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      applicationCategory: [
        {
          value: "全部",
          label: "全部"
        },
        {
          value: "直播",
          label: "直播"
        },
        {
          value: "影视",
          label: "影视"
        },
        {
          value: "视频",
          label: "视频"
        },
        {
          value: "音乐",
          label: "音乐"
        },
        {
          value: "游戏",
          label: "游戏"
        },
        {
          value: "我的",
          label: "我的"
        }
      ], //应用分类列表
      activeCategory: "全部", //选中分类
      marketList: [], //应用市场集合
      dataLineSelections: [], //应用市场选中
      dialogEditMyApp: false, //编辑我的应用窗口
      dialogEditFileUpload: false, //编辑我的应用窗口中图片窗口
      editForm: {}
    };
  },
  props: {
    apk_list_type: String,
    groupId: Number,
    apkList: {
      type: Array,
      default: () => []
    },
    choice: Boolean //是否允许多选 true：单选 false：多选
  },
  computed: {
    //当前登录用户信息
    userInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.userInfo));
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    },
    actionUrlApk() {
      return this.$store.state.fileUrl + "/user_apk/upload";
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    }
  },
  created() {
    this.initLoad();
    console.log(this.fileUrl);
  },
  methods: {
    //查询应用市场
    initLoad() {
      getApkMarketApi({ type: this.activeCategory }).then(res => {
        if (res) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].isShowSelected = false;
            res.data[i].selected = false;
            res.data[i].type = this.apk_list_type;
            res.data[i].groupId = this.groupId;
            for (let j = 0; j < this.apkList.length; j++) {
              if (res.data[i].apkId == this.apkList[j].userApkId) {
                res.data[i].isShowSelected = true;
              }
            }
          }
          this.marketList = res.data;
        }
      });
    },
    //从应用市场添加应用
    addApkListByMarket(obj) {
      if (this.choice) {
        if (!obj.selected) {
          this.dataLineSelections.push(obj);
        } else {
          for (let i = 0; i < this.dataLineSelections.length; i++) {
            if (obj.apkId == this.dataLineSelections[i].apkId) {
              this.dataLineSelections.splice(i, 1);
            }
          }
        }
        obj.selected = !obj.selected;
        this.$store.commit("setFileApkList", this.dataLineSelections);
      } else {
        if (!obj.selected) {
          for (let i = 0; i < this.marketList.length; i++) {
            this.marketList[i].selected = false;
          }
          this.dataLineSelections = [];
          this.dataLineSelections.push(obj);
        } else {
          for (let i = 0; i < this.dataLineSelections.length; i++) {
            if (obj.fileId == this.dataLineSelections[i].fileId) {
              this.dataLineSelections.splice(i, 1);
            }
          }
        }
        obj.selected = !obj.selected;
        this.$store.commit("setFileApkList", this.dataLineSelections);
      }
    },
    //切换分类
    switchActiveCategory(item) {
      this.activeCategory = item.value;
      this.initLoad();
    },
    //打开编辑我的应用窗口
    openEditMyApp(row) {
      this.dialogEditMyApp = true;
      this.editForm = { ...row };
    },
    //关闭我的应用窗口
    closeDialogEditMyApp() {
      this.$refs["editForm"].resetFields();
    },
    //提交修改我的应用
    onSubmitEditMyApp() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          addAppStoreApi(this.editForm).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.dialogEditMyApp = false;
              this.initLoadMarket();
            }
          });
        } else {
          return false;
        }
      });
    },
    //修改窗口 更改图标
    onSubmitEditImgUrl() {
      this.dialogEditFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.editForm.imgUrl = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },
    //修改应用本地上传
    //上传完成事件
    handleImgEditSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.editForm.imgUrl = result.msg;
      } else {
        this.$message.error(res.msg);
      }
       this.initLoad();
    },
    //上传前事件
    beforeImgEditUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //修改上传APK
    //上传前验证判断
    handleEditBefore(file) {
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!isLt50M) {
        this.$message.warning("上传APK大小不能超过 50MB!");
      }
      return isLt50M;
    },
    //上传成功
    handleEditSuccess(response) {
      const res = JSON.parse(Aes.decode(response));
      if (res.type == "success") {
        if (res.msg == "equal") {
          //查询到有相同的APK包提示是否覆盖
          this.$confirm("存在相同APP无法上传,请重新选择!", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            //确认覆盖就将返回的带APPid对象存入form中
            // this.editForm = res.data;
          });
        } else {
          this.$message.success(res.msg);
          delete res.data.apkId;
          this.editForm.packetName = res.data.packetName;
          this.editForm.apkUrl = res.data.apkUrl;
        }
      } else {
        this.$message.error(res.msg);
      }
      this.$refs["apk-editUpload"].clearFiles();
    },
    //删除我的应用
    onSubmitDelMyApp(obj) {
      this.$confirm("此操作将永久删除该应用, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delAppStoreApi({ apkId: obj.apkId }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoadMarket();
          }
        });
      });
    }
  }
};
</script>
<style lang='scss'>
.applicationUtil_view {
  display: flex;
  padding: 10px;
}
.applicationUtil_category {
  width: 130px;
  text-align: center;
  border-right: 1px solid #d7dae2;
  .applicationUtil_category_title {
    font-size: 18px;
    padding-bottom: 10px;
    font-weight: 700;
  }
  .applicationUtil_category_view {
    padding: 10px 0;
    cursor: pointer;
  }
  .applicationUtil_category_view:hover {
    color: #5e72f9;
  }
  .applicationUtil_category_view_selected {
    padding: 10px 0;
    cursor: pointer;
    color: #5e72f9;
    background: #f3f3f3;
  }
}
.applicationUtil_right {
  padding: 0px 20px;
  overflow-y: auto;
  height: 400px;
  width: 90%;
}
.applicationUtil_card {
  display: inline-block;
  border: 1px solid #ebeef5;
  border-radius: 10px;
  padding: 10px 20px;
  position: relative;
}
.applicationUtil_card:hover {
  box-shadow: 0 2px 12px 0 #619aec8c;
}

.applicationUtil_card_selected {
  display: inline-block;
  border: 1px solid #5e72f9;
  border-radius: 10px;
  padding: 10px 20px;
  position: relative;
}
.applicationUtil_card_selected:hover {
  box-shadow: 0 2px 12px 0 #619aec8c;
}
.applicationUtil_card_notSelected {
  border: 1px solid #ebeef5;
  color: #c0c4cc;
  border-radius: 10px;
  padding: 10px 20px;
  position: relative;
}

.applicationUtil_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .applicationUtil_list_right {
    width: 100px;
    margin-left: 20px;
    div {
      margin-top: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>