<template>
  <!-- 界面导航栏 -->
  <div>
    <div class="mainInterface_card_btn">
      <div>
        <i
          class="el-icon-arrow-right"
          style="font-weight:bold;cursor:pointer"
          @click="onSubmitFold"
        ></i>
        卡片设置
      </div>
      <el-button
        type="primary"
        round
        size="small"
        style="width:100px"
        @click="onSubmitEditMainInterface"
      >保存</el-button>
    </div>

    <div class="mainInterface_card_view">
      <el-form
        :model="cardForm"
        ref="cardForm"
        label-position="top"
        label-width="80px"
        size="small"
      >
        <el-form-item label="背景类型" v-if="isOnlyImg==false">
          <el-radio-group v-model="cardForm.type" @change="onChangeType">
            <el-radio label="img">图片</el-radio>
            <el-radio label="video" v-if="cardForm.cardType=='home'">视频</el-radio>
            <el-radio label="color">纯色</el-radio>
            <el-radio label="gradient">渐变</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="背景图片" class="card_view_right_img_card" v-if="cardForm.type=='img'">
          <el-row :gutter="0">
            <el-col
              :xl="12"
              :lg="12"
              :md="12"
              :sm="24"
              :xs="24"
              :offset="0"
              v-for="(item,index) in cardForm.imgList"
              :key="index"
              style="margin-bottom:10px;"
            >
              <div class="card_view_right_img">
                <el-image style="width: 100px; height: 100px" :src="fileUrl+item" fit="contain"></el-image>
                <label @click="delEditImgs(index)" class="delImgIcon">
                  <i class="el-icon-delete" style="transform:rotate(-45deg); color:#FFFFFF;"></i>
                </label>
              </div>
            </el-col>
            <el-col
              :xl="12"
              :lg="12"
              :md="12"
              :sm="24"
              :xs="24"
              :offset="0"
              style="margin-bottom:10px;"
              v-if="cardForm.imgList.length<9"
            >
              <el-dropdown placement="top">
                <div class="addImg_card">
                  <i class="el-icon-plus addImg_card_icon"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="dialogEditImgFileUpload=true"
                    style="padding:0"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                  <el-dropdown-item style="padding:0">
                    <el-upload
                      style="text-align:center"
                      :action="actionUrl"
                      accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                      :headers="header"
                      :data="{type:0,category:'图片'}"
                      :show-file-list="false"
                      :on-success="handleEditImgSuccess"
                      :before-upload="beforeEditImgUpload"
                      multiple
                      :limit="isMaxImgCount"
                    >本地上传</el-upload>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
          <span>多个图以轮播形式展示最多9个</span>
        </el-form-item>

        <el-form-item label="背景视频" v-if="cardForm.type=='video'">
          <el-dropdown placement="top">
            <div>
              <div class="addVideo_card" v-if="!cardForm.videoUrl">
                <i class="el-icon-plus addVideo_card_icon"></i>
              </div>
              <div class="upload_video_address" v-else>
                <label @click="delEditVideo(index)" class="delVideoIcon">
                  <i class="el-icon-delete" style="transform:rotate(-45deg); color:#FFFFFF;"></i>
                </label>
                <div>{{cardForm.videoUrl}}</div>&nbsp;
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="dialogEditVideoFileUpload=true"
                style="padding:0"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
              <el-dropdown-item style="padding:0">
                <el-upload
                  style="text-align:center"
                  :action="actionUrl"
                  accept=".mp4, .flv, .ts, .MP4, .FLV, .TS"
                  :headers="header"
                  :data="{type:1,category:'背景视频'}"
                  :show-file-list="false"
                  :on-success="handleEditVideoSuccess"
                  :before-upload="beforeEditVideoUpload"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>

        <el-form-item label="纯色" v-if="cardForm.type=='color'">
          <div
            style="border:1px solid #DCDFE6;display:flex;justify-content:space-between;width:150px "
          >
            <p>{{cardForm.color}}</p>
            <el-color-picker v-model="cardForm.color" @change="onSelectedColor"></el-color-picker>
          </div>
        </el-form-item>

        <el-form-item label="渐变" v-if="cardForm.type=='gradient'">
          <el-form-item>
            <el-row :gutter="10">
              <el-col
                :span="6"
                :offset="0"
                v-for="gradient in gradientList"
                :key="gradient.id"
                style="margin-bottom:10px;"
              >
                <div
                  :class="cardForm.gradientId==gradient.id ?'card_view_right_gradient_selected  card_view_right_gradient':'card_view_right_gradient' "
                  v-bind:style="{background:'linear-gradient('+gradient.type+', '+gradient.color1+','+gradient.color2+')'}"
                  @click="onSelectedGradient(gradient)"
                ></div>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form-item>
        <p style="border:1px dashed #eee;margin:10px 0"></p>

        <!-- <el-form-item label="展示样式">
          <el-radio-group v-model="cardForm.style">
            <el-radio
              label="info"
              v-if="cardForm.cardType=='introduce' || cardForm.cardType=='weather'"
            >信息</el-radio>
            <el-radio label="none">无样式</el-radio>
            <el-radio label="text">文字</el-radio>
            <el-radio label="icon">图标</el-radio>
            <el-radio label="qr">二维码</el-radio>
          </el-radio-group>
        </el-form-item>-->
        <el-form-item label="展示样式">
          <div class="showRadio_group">
            <el-tooltip
              effect="dark"
              content="场所信息"
              placement="top"
              v-if="cardForm.cardType=='introduce'"
            >
              <div
                :class="cardForm.style=='info'?'radio_info_selected':'radio_info'"
                @click="cardForm.style='info'"
              >
                <img src="../assets/interface/info.png" />
              </div>
            </el-tooltip>

            <el-tooltip
              effect="dark"
              content="天气信息"
              placement="top"
              v-if="cardForm.cardType=='weather'"
            >
              <div
                :class="cardForm.style=='info'?'radio_info_selected':'radio_info'"
                @click="cardForm.style='info'"
              >
                <img src="../assets/interface/info.png" />
              </div>
            </el-tooltip>

            <el-tooltip effect="dark" content="无说明" placement="top">
              <div
                :class="cardForm.style=='none'?'radio_info_selected':'radio_info'"
                @click="cardForm.style='none'"
              >
                <img src="../assets/interface/nothing.png" />
              </div>
            </el-tooltip>
            <el-tooltip effect="dark" content="悬浮文字" placement="top">
              <div
                :class="cardForm.style=='text'?'radio_info_selected':'radio_info'"
                @click="cardForm.style='text'"
              >
                <img src="../assets/interface/text.png" />
              </div>
            </el-tooltip>
            <el-tooltip effect="dark" content="悬浮图标" placement="top">
              <div
                :class="cardForm.style=='icon'?'radio_info_selected':'radio_info'"
                @click="cardForm.style='icon'"
              >
                <img src="../assets/interface/icon.png" />
              </div>
            </el-tooltip>
            <el-tooltip effect="dark" content="二维码" placement="top">
              <div
                :class="cardForm.style=='qr'?'radio_info_selected':'radio_info'"
                @click="cardForm.style='qr'"
              >
                <img src="../assets/interface/qrImg.png" />
              </div>
            </el-tooltip>
          </div>
        </el-form-item>
      </el-form>
      <el-form ref="reqForm" label-width="90px" label-position="left" size="small">
        <el-form-item label="标题" v-if="cardForm.style=='icon' || cardForm.style=='text'">
          <el-input v-model="cardForm.title"></el-input>
        </el-form-item>

        <el-form-item label="图标" v-if="cardForm.style=='icon'">
          <div class="card_img" v-if="cardForm.iconUrl">
            <el-image
              style="width: 80px; height: 80px"
              :src="fileUrl+cardForm.iconUrl"
              fit="contain"
            ></el-image>
          </div>
          <div class="card_img_upload">
            <div>
              <el-button
                type="primary"
                size="mini"
                style="width:80px"
                @click="dialogEditIconFileUpload=true"
              >资源库</el-button>
            </div>
            <div>
              <el-upload
                :action="actionUrl"
                accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                :headers="header"
                :data="{type:0,category:'图标'}"
                :show-file-list="false"
                :on-success="handleEditIconSuccess"
                :before-upload="beforeEditIconUpload"
              >
                <el-button plain type="primary" size="mini" style="width:80px">本地上传</el-button>
              </el-upload>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="二维码格式" v-if="cardForm.style=='qr'">
          <el-radio v-model="cardForm.qrType" label="url">链接</el-radio>
          <el-radio v-model="cardForm.qrType" label="img">图片</el-radio>
        </el-form-item>
        <el-form-item label="链接" v-if="cardForm.style=='qr' && cardForm.qrType =='url'">
          <el-input v-model="cardForm.qrUrl"></el-input>
        </el-form-item>

        <el-form-item label="二维码图片" v-if="cardForm.style=='qr' && cardForm.qrType=='img'">
          <div class="card_img" v-if="cardForm.qrImg">
            <el-image style="width: 80px; height: 80px" :src="fileUrl+cardForm.qrImg" fit="contain"></el-image>
          </div>
          <div class="card_img_upload">
            <div>
              <el-button
                type="primary"
                size="mini"
                style="width:80px"
                @click="dialogEditQrImgFileUpload=true"
              >资源库</el-button>
            </div>
            <div>
              <el-upload
                :action="actionUrl"
                accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                :headers="header"
                :data="{type:0,category:'图片'}"
                :show-file-list="false"
                :on-success="handleEditQrImgSuccess"
                :before-upload="beforeEditQrImgUpload"
              >
                <el-button plain type="primary" size="mini" style="width:80px">本地上传</el-button>
              </el-upload>
            </div>
          </div>
        </el-form-item>

        <p style="border:1px dashed #eee;margin:10px 0" v-if="isOnlyImg==false"></p>
        <el-form-item label="跳转类型" v-if="isOnlyImg==false">
          <el-select v-model="cardForm.reqType" @change="switchReqType">
            <el-option
              v-for="item in reqOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="展示方式"
          v-if="cardForm.reqType=='img' || cardForm.reqType=='video' || cardForm.reqType=='qrCode'"
        >
          <el-radio v-model="cardForm.showType" label="fullscreen">全屏</el-radio>
          <el-radio v-model="cardForm.showType" label="alert">弹窗</el-radio>
        </el-form-item>

        <!-- APK跳转 -->
        <el-form-item label="跳转APK" v-if="cardForm.reqType=='apk'">
          <p>{{cardForm.apkName}}</p>
          <div class="card_upload">
            <div>
              <el-button
                type="primary"
                size="mini"
                style="width:80px"
                @click="dialogEditApplication=true"
              >资源库</el-button>
            </div>
            <div>
              <el-upload
                ref="apk-upload"
                :action= "actionUrlApk"
                accept=".apk"
                :on-success="handleApkSuccess"
                :before-upload="handleApkBefore"
                :headers="header"
                :show-file-list="true"
              >
                <el-button plain type="primary" size="mini" style="width:80px;">本地上传</el-button>
              </el-upload>
            </div>
          </div>
        </el-form-item>
        <!-- 内置页面跳转 -->
        <el-form-item label="跳转页面" v-if="cardForm.reqType=='page'">
          <el-select v-model="cardForm.pageId">
            <el-option
              v-for="item in contentOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 二维码跳转 -->
        <el-form-item label="格式" v-if="cardForm.reqType=='qrCode'">
          <el-radio v-model="cardForm.reqQrType" label="link">链接</el-radio>
          <el-radio v-model="cardForm.reqQrType" label="img">图片</el-radio>
        </el-form-item>
        <el-form-item label="链接地址" v-if="cardForm.reqType=='qrCode' && cardForm.reqQrType=='link'">
          <el-input v-model="cardForm.qrUrl"></el-input>
        </el-form-item>

        <el-form-item label="二维码图片" v-if="cardForm.reqType=='qrCode' && cardForm.reqQrType=='img'">
          <div class="card_img" v-if="cardForm.reqQr">
            <el-image style="width: 80px; height: 80px" :src="fileUrl+cardForm.reqQr" fit="contain"></el-image>
          </div>

          <div class="card_img_upload">
            <div>
              <el-button
                type="primary"
                size="mini"
                style="width:80px"
                @click="dialogEditReqQrFileUpload=true"
              >资源库</el-button>
            </div>
            <div>
              <el-upload
                :action="actionUrl"
                accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                :headers="header"
                :data="{type:0,category:'图片'}"
                :show-file-list="false"
                :on-success="handleEditReqQrSuccess"
                :before-upload="beforeEditReqQrUpload"
              >
                <el-button plain type="primary" size="mini" style="width:80px">本地上传</el-button>
              </el-upload>
            </div>
          </div>
        </el-form-item>

        <!-- 图片跳转 -->
        <el-form-item label="跳转图片" v-if="cardForm.reqType=='img'">
          <!-- <el-row :gutter="0" v-if="cardForm.reqList.length>0">
            <el-col
              :xl="12"
              :lg="12"
              :md="12"
              :sm="24"
              :xs="24"
              :offset="0"
              v-for="(item,index) in cardForm.reqList"
              :key="index"
              style="margin-bottom:10px;"
            >
              <div class="card_img">
                <el-image style="width: 80px; height: 80px" :src="fileUrl+item" fit="contain"></el-image>
                <label @click="delReqImg(index)" class="delImgIcon">
                  <i class="el-icon-delete" style="transform:rotate(-45deg); color:#FFFFFF;"></i>
                </label>
              </div>
            </el-col>
          </el-row>-->
          <div class="card_img" v-if="cardForm.reqImgUrl">
            <el-image
              style="width: 80px; height: 80px"
              :src="fileUrl+cardForm.reqImgUrl"
              fit="contain"
            ></el-image>
          </div>

          <div class="card_upload">
            <div>
              <el-button
                type="primary"
                size="mini"
                style="width:80px"
                @click="dialogEditReqImgFileUpload=true"
              >资源库</el-button>
            </div>
            <div>
              <el-upload
                :action="actionUrl"
                accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                :headers="header"
                :data="{type:0,category:'图片'}"
                :show-file-list="false"
                :on-success="handleEditReqImgSuccess"
                :before-upload="beforeEditReqImgUpload"
              >
                <el-button plain type="primary" size="mini" style="width:80px">本地上传</el-button>
              </el-upload>
            </div>
          </div>
        </el-form-item>

        <!-- 视频跳转 -->
        <el-form-item label="跳转视频" v-if="cardForm.reqType=='video'">
          <video
            v-if="cardForm.videoUrl"
            :src="fileUrl+cardForm.videoUrl"
            controls="controls"
            style="width:100%;"
          ></video>
          <div class="card_upload">
            <div>
              <el-button
                type="primary"
                size="mini"
                style="width:80px"
                @click="dialogEditReqVideoFileUpload=true"
              >资源库</el-button>
            </div>
            <div>
              <el-upload
                :action="actionUrl"
                accept=".mp4, .flv, .ts, .MP4, .FLV, .TS"
                :headers="header"
                :data="{type:1,category:'背景视频'}"
                :show-file-list="false"
                :on-success="handleEditReqVideoSuccess"
                :before-upload="beforeEditReqVideoUpload"
              >
                <el-button plain type="primary" size="mini" style="width:80px">本地上传</el-button>
              </el-upload>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="网页地址" v-if="cardForm.reqType=='url'">
          <el-input v-model="cardForm.url"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <!-- 背景图片 -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditImgFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogEditImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload
        :choice="false"
        :type="0"
        :count="this.isMaxImgCount"
        :category="'图片'"
        v-if="dialogEditImgFileUpload"
      ></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditImgFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditImg" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 背景视频 -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditVideoFileUpload"
      :close-on-click-modal="false"
      width="55%"
    >
      <span slot="title">资源库</span>
      <FileUploadVideo
        :type="1"
        :category="'背景视频'"
        v-on:callBackPath="onSubmitEditVideoUrl"
        v-if="dialogEditVideoFileUpload"
      ></FileUploadVideo>
    </el-dialog>

    <!-- 展示样式图标 -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditIconFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogEditImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图标'" v-if="dialogEditIconFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditIconFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditIcon" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 二维码图片 -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditQrImgFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogEditImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图片'" v-if="dialogEditQrImgFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditQrImgFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditQrImg" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 跳转APK -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditApplication"
      :close-on-click-modal="false"
      width="50%"
      @closed="closeDialogEditApplication"
    >
      <span slot="title">新增应用</span>
      <Application v-if="dialogEditApplication" :choice="false"></Application>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditApplication=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditApplication">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 跳转二维码图片 -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditReqQrFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogEditImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图片'" v-if="dialogEditReqQrFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditReqQrFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditReqQrImg" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 跳转图片 -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditReqImgFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogEditImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload
        :choice="true"
        :type="0"
        :count="this.isMaxReqImgCount"
        :category="'图片'"
        v-if="dialogEditReqImgFileUpload"
      ></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditReqImgFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditReqImg" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 背景视频 -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditReqVideoFileUpload"
      :close-on-click-modal="false"
      width="55%"
    >
      <span slot="title">资源库</span>
      <FileUploadVideo
        :type="1"
        :category="'背景视频'"
        v-on:callBackPath="onSubmitEditReqVideoUrl"
        v-if="dialogEditReqVideoFileUpload"
      ></FileUploadVideo>
    </el-dialog>
  </div>
</template>
<script>
import Aes from "../utils/aes";
import {
  getGradientListApi,
  addAppStoreApi,
  getContentPageApi
} from "../api/data";
import FileUpload from "./FileUpload";
import Application from "./Application";
import FileUploadVideo from "./FileUploadVideo";
export default {
  components: {
    FileUpload,
    Application,
    FileUploadVideo
  },
  data() {
    return {
      reqOption: [
        { value: "none", label: "不跳转" },
        { value: "apk", label: "APK跳转" },
        { value: "page", label: "内置页面跳转" },
        { value: "qrCode", label: "二维码跳转" },
        { value: "img", label: "图片跳转" },
        { value: "video", label: "视频跳转" },
        { value: "url", label: "网页跳转" }
      ],
      gradientList: [],
      dialogEditImgFileUpload: false, //背景图片窗口
      dialogEditVideoFileUpload: false, //背景视频窗口
      dialogEditIconFileUpload: false, //展示样式图标窗口
      dialogEditQrImgFileUpload: false, //展示样式二维码图片窗口
      dialogEditApplication: false, //跳转APK窗口
      dialogEditReqQrFileUpload: false, //跳转二维码窗口
      dialogEditReqImgFileUpload: false, //跳转图片窗口
      dialogEditReqVideoFileUpload: false, //跳转视频窗口
      contentOption: [] //内容页面选项
    };
  },
  props: {
    isShowWindow: Boolean,
    cardForm: {},
    //背景类型只显示图片
    isOnlyImg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    //最大上传图片数
    isMaxImgCount() {
      return 9 - this.cardForm.imgList.length;
    },
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    }, //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    },//文件上传
     actionUrlApk() {
      return this.$store.state.fileUrl + "/user_apk/upload";
    }
  },
  created() {
    this.initGradient();
    this.initContentPage();
  },
  methods: {
    initLoad() {},
    //初始化内容页面选项
    initContentPage() {
      getContentPageApi({ groupId: this.placesInfo.groupId }).then(res => {
        if (res) this.contentOption = res.data;
      });
    },
    //初始化渐变色列表
    initGradient() {
      getGradientListApi().then(res => {
        if (res) this.gradientList = res.data;
      });
    },
    //背景图片
    //上传图片完成事件
    handleEditImgSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.cardForm.imgList.push(result.msg);
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传图片前事件
    beforeEditImgUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库图片添加完成事件
    onSubmitEditImg() {
      this.dialogEditImgFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        for (let i = 0; i < this.$store.state.fileList.length; i++) {
          this.cardForm.imgList.push(this.$store.state.fileList[i].filePath);
        }
        this.$store.commit("setFileList", []);
      }
    },
    //图片窗口关闭事件
    closeDialogEditImgFileUpload() {
      this.$store.commit("setFileList", []);
    },
    //删除图片集合
    delEditImgs(index) {
      this.cardForm.imgList.splice(index, 1);
    },

    //删除视频
    delEditVideo() {
      this.cardForm.videoUrl = "";
      let video = document.getElementById("video");
      video.src = "";
    },
    //上传视频完成事件
    handleEditVideoSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.cardForm.videoUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传视频前事件
    beforeEditVideoUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!isLt5M) {
        this.$message.warning("上传视频大小不能超过 50MB!");
      }
      return isLt5M;
    },
    //资源库视频添加完成事件
    onSubmitEditVideoUrl(path) {
      this.dialogEditVideoFileUpload = false;
      this.cardForm.videoUrl = path;
    },

    //上传导航图标完成事件
    handleEditIconSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.cardForm.iconUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传展示样式图标前事件
    beforeEditIconUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库展示样式图标添加完成事件
    onSubmitEditIcon() {
      this.dialogEditIconFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.cardForm.iconUrl = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },

    //上传展示样式二维码图片完成事件
    handleEditQrImgSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.cardForm.qrImg = result.msg;
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传展示样式二维码图片前事件
    beforeEditQrImgUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库展示样式二维码图片添加完成事件
    onSubmitEditQrImg() {
      this.dialogEditQrImgFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.cardForm.qrImg = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },

    //关闭导航apk窗口
    closeDialogEditApplication() {
      this.$store.commit("setFileApkList", []);
    },
    //添加上传APK
    //资源库添加应用列表
    onSubmitEditApplication() {
      if (this.$store.state.fileApkList.length > 0) {
        this.cardForm.apkId = this.$store.state.fileApkList[0].apkId;
        this.cardForm.apkName = this.$store.state.fileApkList[0].cnName;
        this.$store.commit("setFileApkList", []);
        this.dialogEditApplication = false;
      }
    },
    //上传前验证判断
    handleApkBefore(file) {
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!isLt50M) {
        this.$message.warning("上传APK大小不能超过 50MB!");
      }
      return isLt50M;
    },
    //上传成功
    handleApkSuccess(response) {
      const res = JSON.parse(Aes.decode(response));
      if (res.type == "success") {
        if (res.msg == "equal") {
          //查询到有相同的APK包提示是否覆盖
          this.$confirm("资源库中存在相同APP,是否继续上传?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            //确认覆盖就将返回的带APPid对象存入
            res.data.apkType = "我的";
            this.onSubmitAddApk(res.data);
          });
        } else {
          res.data.publicity = "on";
          res.data.apkType = "我的";
          this.onSubmitAddApk(res.data);
        }
      } else {
        this.$message.error(res.msg);
      }
      // 页面上存在多个upload时使用此方法清除
      const mainImg = this.$refs["apk-upload"];
      if (mainImg && mainImg.length) {
        mainImg.forEach(item => {
          item.clearFiles();
        });
      }
    },
    //添加APK
    onSubmitAddApk(obj) {
      addAppStoreApi(obj).then(res => {
        if (res) {
          //等待后台返回
          this.cardForm.apkId = res.data.apkId;
          this.cardForm.apkName = res.data.cnName;
          this.$message.success(res.msg);
        }
      });
    },

    //上传跳转二维码图片完成事件
    handleEditReqQrSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.cardForm.reqQr = result.msg;
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传跳转二维码图片前事件
    beforeEditReqQrUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库跳转二维码图片添加完成事件
    onSubmitEditReqQrImg() {
      this.dialogEditReqQrFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.cardForm.reqQr = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },

    //删除跳转图片集合
    // delReqImg(index) {
    //   this.cardForm.reqImgUrl="";
    // },
    //上传跳转图片完成事件
    handleEditReqImgSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.cardForm.reqImgUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传跳转图片前事件
    beforeEditReqImgUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库跳转图片添加完成事件
    onSubmitEditReqImg() {
      this.dialogEditReqImgFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        for (let i = 0; i < this.$store.state.fileList.length; i++) {
          this.cardForm.reqImgUrl = this.$store.state.fileList[0].filePath;
        }
        this.$store.commit("setFileList", []);
      }
    },

    //上传导航视频完成事件
    handleEditReqVideoSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.cardForm.videoUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传导航视频前事件
    beforeEditReqVideoUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 50MB!");
      }
      return isLt5M;
    },
    //资源库导航视频添加事件
    onSubmitEditReqVideoUrl(path) {
      this.dialogEditReqVideoFileUpload = false;
      this.cardForm.videoUrl = path;
    },
    //选择不同的跳转类型
    switchReqType(val) {
      switch (val) {
        case "apk":
          this.cardForm.showType = "fullscreen";
          break;
        case "page":
          this.cardForm.showType = "fullscreen";
          break;
        case "url":
          this.cardForm.showType = "fullscreen";
          break;
        default:
          this.cardForm.showType = "alert";
          break;
      }
    },
    //提交编辑
    onSubmitEditMainInterface() {
      this.$emit("callbackCard", this.cardForm);
    },
    //选择渐变
    onSelectedGradient(obj) {
      this.cardForm.gradientId = obj.id;
      this.cardForm.gradient = obj;
      this.$emit("callbackGradient", this.cardForm);
    },
    //选择纯色
    onSelectedColor() {
      this.$emit("callbackColor", this.cardForm);
    },
    //选择卡片背景类型  纯卡片主题使用
    onChangeType(val) {
      this.$emit("callbackType", this.cardForm, val);
    },
    //折叠
    onSubmitFold() {
      this.$emit("callbackIsShowWindow", !this.isShowWindow);
    }
  }
};
</script>
<style lang='scss'>
.mainInterface_card_view {
  padding: 20px;
  overflow-y: scroll;
  height: calc(100vh - 120px);
  width: 100%;
  .el-radio {
    margin-right: 12px;
  }
}
.mainInterface_card_view::-webkit-scrollbar {
  width: 6px;
}
.mainInterface_card_view:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.8);
}
.mainInterface_card_view::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
.mainInterface_card_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  border-bottom: 1px solid #d7dae2;
  padding: 10px;
}

.delImgIcon {
  width: 50px;
  height: 30px;
  background: #f56c6c;
  transform: rotate(45deg);
  position: absolute;
  right: -20px;
  top: -8px;
  text-align: center;
  padding-top: 5px;
  box-shadow: 0 1px 1px #ccc;
  cursor: pointer;
}
.card_view_right_img_card {
  .card_view_right_img {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    width: 100px;
    height: 100px;
    background: rgba(235, 235, 235, 0.5);
    border-radius: 6px;
    border: 1px dashed #d9d9d9;
  }
  .addImg_card {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px;
    .addImg_card_icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
  }
  .addImg_card:hover {
    border-color: #5e72f9;
  }
}

.addVideo_card {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  text-align: center;
  .addVideo_card_icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.addVideo_card:hover {
  border-color: #5e72f9;
}

.upload_video_address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  height: 100px;
  width: 200px;
  overflow: hidden;
  position: relative;
  div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .delVideoIcon {
    width: 50px;
    height: 30px;
    background: #f56c6c;
    transform: rotate(45deg);
    position: absolute;
    right: -17px;
    top: -7px;
    text-align: center;
    padding-top: 4px;
    box-shadow: 0 1px 1px #ccc;
    opacity: 1;
    cursor: pointer;
  }
}
// 渐变选中
.card_view_right_gradient_selected {
  border: 1px solid #909399;
  box-shadow: 0 2px 12px 0 #619aec8c;
}
.card_view_right_gradient {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 4px;
}
.card_view_right_gradient:hover {
  box-shadow: 0 2px 12px 0 #619aec8c;
}
//展示样式图标
.card_img {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 80px;
  height: 80px;
  background: rgba(235, 235, 235, 0.5);
  border-radius: 6px;
  border: 1px dashed #d9d9d9;
}
.card_img_upload {
  display: flex;
  justify-content: space-between;
}
//跳转类型
.card_upload {
  display: flex;
  justify-content: space-between;
}

.showRadio_group {
  display: flex;
  justify-content: space-around;
}
.radio_info {
  width: 32px;
  height: 32px;
  background: #f3f3f3;
  border-radius: 4px;
  cursor: pointer;
}
.radio_info_selected {
  width: 32px;
  height: 32px;
  background: #c0c4cc;
  border-radius: 4px;
  cursor: pointer;
}
.radio_info:hover {
  background: #c0c4cc;
}
</style>